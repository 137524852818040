import React from "react"

export default function Introduction({ className = '', children, Artwork }) {
    return (
        <div className="">
            <div className={`container vh-100 flex flex-row flex-wrap ${className}`}>
                <div className="w-full lg:w-2/3 py-8 flex flex-col justify-center">
                    {children}
                </div>
                <div className="w-full lg:w-1/3 py-0 lg:py-8 flex items-center">
                    <div className="intro-img">
                        <Artwork />
                    </div>
                </div>
            </div>
        </div>
    )
}