import React from "react"
import Slider from "react-slick"
import "../assets/css/slick.css"

export default function TextSlider({ data }) {
    const settings = {
        lazyLoad: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                centerPadding: '80px'
            }
        }, {
            breakpoint: 767,
            settings: {
                centerMode: false
            }
        }]
    };

    return <Slider {...settings}>
        {data.map(({ text, name, company, url }, index) => {
            return (
                <div key={index}>
                    <div className="container">
                        <div className="w-full lg:w-2/3 mx-auto text-center">
                            <h3>{company}</h3>
                            <p className="cl-50">{text}</p>
                            <p className="text-smaller">
                                {name}<br />
                            </p>
                        </div>
                    </div>
                </div>
            );
        })}
    </Slider>
}