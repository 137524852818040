import React from "react"

export default function ClientsBrands({ modalContext }) {
    return (
        <div id="clients-brands" className="component double-padding bg-darker gradient-left-purple pb-32">
            <h2 className="text-center mb-0">Clients &amp; brands we've worked on<span className="symbol symbol-purple"><span></span></span></h2>
            <p className="cl-primary text-center mb-16">Since 2005.</p>
            <div className="container">
                <div className="smaller-lists columns columns-4">
                    <ul>
                        <li><strong>Agencies</strong></li>
                        <li>Imbongi Communications</li>
                        <li>Inspiration Advertising</li>
                        <li>Liquorice Digital</li>
                        <li>Satellite Brand Communications</li>
                        <li>Tomcat Advertising</li>
                        <li>We Are Monsters</li>
                        <li>Wildfire Interactive</li>
                        <li>Brandpride Creative</li>
                        <li>Magnetic Creative</li>
                        <li>Lushomo</li>
                        <li>Studio Co</li>
                    </ul>
                    <ul>
                        <li><strong>Automotive</strong></li>
                        <li>Audi</li>
                        <li>Volkswagen</li>
                    </ul>
                    <ul>
                        <li><strong>Conferences</strong></li>
                        <li>HICA</li>
                        <li>ICLAM</li>
                        <li>IGBP</li>
                        <li>LPG</li>
                        <li>PACS</li>
                        <li>WCVA</li>
                        <li>WFNMB</li>
                    </ul>
                    <ul>
                        <li><strong>Consumer &amp; Retail</strong></li>
                        <li>b-smart</li>
                        <li>Cape Consumers</li>
                        <li>Constantia Village Courtyard</li>
                        <li>FunWorld</li>
                        <li>iPoint</li>
                        <li>NoBucks</li>
                        <li>Vavavox</li>
                        <li>The Baking Tin</li>
                        <li>Voom Apparel</li>
                    </ul>
                    <ul>
                        <li><strong>Engineering &amp; Construction</strong></li>
                        <li>Binastore</li>
                        <li>DHD Construction</li>
                        <li>DMSP Engineers</li>
                        <li>Inisys Africa</li>
                        <li>Lesedi Nuclear Services</li>
                        <li>Linea</li>
                        <li>Unyazi</li>
                        <li>Valiant Project Management</li>
                    </ul>
                    <ul>
                        <li><strong>Film Services</strong></li>
                        <li>Artistes Personal Management</li>
                        <li>Public Image Models</li>
                        <li>Rocket Films Africa</li>
                    </ul>
                    <ul>
                        <li><strong>Financial</strong></li>
                        <li>Acorn Equity</li>
                        <li>Cedar Capital</li>
                        <li>Delfin</li>
                        <li>Evolution Group</li>
                        <li>First Guarantee Pension</li>
                        <li>Grand Towers</li>
                        <li>Gryphon Asset Management</li>
                        <li>Investment Data Services</li>
                        <li>Metropolitan</li>
                        <li>MMI International</li>
                        <li>MoneySmart</li>
                        <li>NOVARE</li>
                        <li>Old Mutual</li>
                        <li>Rademeyer Wesson</li>
                        <li>SALA Pension Fund</li>
                        <li>Sanlam</li>
                    </ul>
                    <ul>
                        <li><strong>Food &amp; Beverage</strong></li>
                        <li>Amarula</li>
                        <li>Ben &amp; Jerry's</li>
                        <li>Castle Lite</li>
                        <li>Crystal Falls</li>
                        <li>Esprit</li>
                        <li>Health Island</li>
                        <li>Landskroon Wines</li>
                        <li>Liqui-Fruit</li>
                        <li>Live in Taste</li>
                        <li>Nederburg</li>
                        <li>Pasta Grande</li>
                        <li>Peninsula Beverages</li>
                        <li>Pioneer Foods</li>
                        <li>Pongrácz</li>
                        <li>Richelieu</li>
                        <li>Robertsons</li>
                        <li>Sir Fruit</li>
                        <li>Sir Fresh</li>
                        <li>Stimorol</li>
                        <li>Terroir Wine Awards</li>
                        <li>The Fresh Press</li>
                        <li>Wilde Juice</li>
                        <li>Zing Juice</li>
                        <li>Lieben Wines &amp; Spirits</li>
                    </ul>
                    <ul>
                        <li><strong>Legal</strong></li>
                        <li>Broodryk Attorneys</li>
                        <li>Fairbridges Attorneys</li>
                        <li>Starzmann Consulting</li>
                    </ul>
                    <ul>
                        <li><strong>NGOs</strong></li>
                        <li>Epilepsy South Africa</li>
                        <li>Home of Hope</li>
                        <li>Justice ACTs</li>
                        <li>Kay Mason Foundation</li>
                        <li>Mazda Wildlife Fund</li>
                        <li>Mashuka</li>
                        <li>SANCA Western Cape</li>
                    </ul>
                    <ul>
                        <li><strong>Professional Services</strong></li>
                        <li>Career Seekers</li>
                        <li>Global Migration Services</li>
                        <li>JSSL</li>
                        <li>Make Sense Management</li>
                        <li>My Payroll Online</li>
                        <li>PixelFaerie</li>
                        <li>Silversoft</li>
                        <li>Sitters4U</li>
                        <li>The Writing School</li>
                        <li>CICS ltd.</li>
                        <li>OHSA Health &amp; Safety Consultants</li>
                    </ul>
                    <ul>
                        <li><strong>Property</strong></li>
                        <li>Aview Properties</li>
                        <li>Broll Property Group</li>
                    </ul>
                    <ul>
                        <li><strong>Recruitment</strong></li>
                        <li>Career Seekers</li>
                        <li>ITO</li>
                    </ul>
                    <ul>
                        <li><strong>Sport</strong></li>
                        <li>SA Referees</li>
                    </ul>
                    <ul>
                        <li><strong>Travel &amp; Tourism</strong></li>
                        <li>Book Victoria Falls</li>
                        <li>Expedition Africa</li>
                        <li>Fairfield Tours</li>
                        <li>Kariba Tours</li>
                        <li>Silvercross Helicopters</li>
                        <li>Spirit of Africa</li>
                        <li>Thebe Exhibitions &amp; Projects</li>
                        <li>Ulf Car Rental</li>
                        <li>the SPACE.</li>
                    </ul>
                    <ul>
                        <li><strong>Technology</strong></li>
                        <li>AMD</li>
                        <li>Civic Ledger</li>
                        <li>Flexion Mobile</li>
                        <li>Jurumani</li>
                        <li>Lenovo</li>
                        <li>Nimbus Digital</li>
                    </ul>
                </div>
            </div>
            <p className="text-center mt-12"><button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-purple">Get in Touch</button></p>
        </div>
    )
}