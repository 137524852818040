import React, { useContext } from "react"
import MyLink from "../components/link"
import { ModalContext } from "../components/modalContext"
import Introduction from "../components/intro"
import OurServices from "../components/ourServices"
import FavouriteTech from "../components/favouriteTech"
import RecentWork from "../components/recentWork"
import ClientsBrands from "../components/clientsBrands"
import Testimonials from "../components/testimonials"
import Artwork from "../assets/img/artwork/artwork-00.svg"

export default function Home() {
    const modalContext = useContext(ModalContext);

    return (
        <div>
            <Introduction Artwork={Artwork}>
                <h1>Experience<br/><span>Digital</span> Innovation</h1>
                <p className="text-larger"><strong>Established in 2005</strong>, Artificial Studio is a small team of digital professionals specialising in creating <MyLink to="/website-design-and-development/">websites</MyLink> &amp; <MyLink to="/web-application-development/">progressive web apps</MyLink>, as well as a range of supporting digital services.</p>
                {/*
                    <p className="text-larger">We are based in <strong>Cape Town, South Africa (GMT+2)</strong>, and work with <strong>local</strong> and <strong>international</strong> clients of all sizes in <strong>various industries</strong>.</p>
                    <p className="text-larger">Our services also include <MyLink to="/hosting-service/">website hosting</MyLink>, <MyLink to="/domain-registration-and-management/">domain management</MyLink>, <MyLink to="/website-design-and-development/">support</MyLink>, <MyLink to="/digital-marketing/">digital marketing</MyLink> and <MyLink to="/search-engine-optimisation/">search engine optimisation</MyLink>.</p>
                */}
                <p className="mt-12 mb-0">
                    <a href="#our-services" className="btn btn-lg btn-blue">Our Services</a>
                    <button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-lg btn-blue">Get in Touch</button>
                </p>
            </Introduction>
            <OurServices />
            <FavouriteTech />
            <RecentWork />
            <ClientsBrands modalContext={modalContext} />
            <Testimonials />
        </div>
    )
}
