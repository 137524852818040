import React from "react"
import TextSlider from "../components/textSlider"

export default function Testimonials() {
    return (
        <div id="testimonials" className="component double-padding pb-32">
            <h2 className="text-center">What our clients say<span className="symbol symbol-green"><span></span></span></h2>
            <TextSlider data={[
                {
                    text: '"Artificial Studio have created a sleek and professional webpage for my small business and have dealt well with my many changes/additions along the way. Due to their fast response and openness I look forward to making use of their expert services indefinitely."',
                    name: 'Craig',
                    company: 'Valiant Check In',
                    url: 'https://valiantcheckin.co.za/'
                },{
                    text: '"Artificial Studio is a highly-skilled and professional team who I would recommend to any client wanting a new, easy-to-manage website. They re-designed and developed our very outdated website into an aesthetically pleasing site with a great user journey. They were always available to answer any queries and went above and beyond throughout (and after) the project."',
                    name: 'Katherine',
                    company: 'The Baking Tin',
                    url: 'https://thebakingtin.co.za/'
                },{
                    text: '"Le Roux and his team at Artificial Studio have been an absolute pleasure to work with. Professional and efficient, Artificial Studio deliver when they say they will. Excellent communication, technical ability and interpretation of the brief means that crafting your dream website is a lot easier than it used to be! Highly recommended."',
                    name: 'Cameron',
                    company: 'the SPACE.',
                    url: 'https://enjoythespace.com/'
                }
            ]} />
        </div>
    )
}