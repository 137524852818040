import React from "react"
import LogoGatsby from "../assets/img/logos/gatsby.svg"
import LogoInertia from "../assets/img/logos/inertia.svg"
import LogoLaravel from "../assets/img/logos/laravel.svg"
import LogoPHP from "../assets/img/logos/php.svg"
import LogoWordPress from "../assets/img/logos/wordpress.svg"

export default function FavouriteTech() {
    return (
        <div id="our-favourite-tech" className="component">
            <h2 className="text-center">Our Favourite Tech<span className="symbol symbol-green"><span></span></span></h2>
            <div className="component bg-white px-6">
                <div className="logos">
                    <a aria-label="React logo" href="https://reactjs.org/" className="logo-react" target="_blank" rel="noreferrer"><img src="/static/img/logos/react.png" alt="React Logo" /></a>
                    <a aria-label="Gatsby logo" href="https://www.gatsbyjs.com/" className="logo-gatsby" target="_blank" rel="noreferrer"><LogoGatsby /></a>
                    <a aria-label="Inertia logo" href="https://inertiajs.com/" className="logo-inertia" target="_blank" rel="noreferrer"><LogoInertia /></a>
                    <a aria-label="PHP logo" href="https://www.php.net/" className="logo-php" target="_blank" rel="noreferrer"><LogoPHP /></a>
                    <a aria-label="Themosis Framework logo" href="https://framework.themosis.com/" className="logo-themosis" target="_blank" rel="noreferrer"><img src="/static/img/logos/themosis.png" alt="Themosis Framework Logo" /></a>
                    <a aria-label="Wordpress logo" href="https://wordpress.org/" className="logo-wordpress" target="_blank" rel="noreferrer"><LogoWordPress /></a>
                    <a aria-label="Laravel logo" href="https://laravel.com/" className="logo-laravel" target="_blank" rel="noreferrer"><LogoLaravel /></a>
                </div>
            </div>
        </div>
    )
}